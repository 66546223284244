import { Box } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, createBrowserRouter } from "react-router-dom";
import Discovery from "../components/pages/Discovery";
import LoginData from "../components/pages/LoginData";
import ManagementSettings from "../components/pages/Managementsetting";
import MemberInformation from "../components/pages/MemberInformation";
import Sidebar from "../components/parts/Sidebar";
import { ROUTERS } from "../lib/routers";
import { Fragment } from "react";

const ProtectedRoutes = () => {
  const { aduserid, adcompanyid } = useSelector(
    (state: TUserState) => state.userInfo
  );

  return !!aduserid && !!adcompanyid ? (
    <Fragment>
      <Sidebar />
      <Box ml="75px" p="20px">
        <Outlet />
      </Box>
    </Fragment>
  ) : (
    <Navigate to={ROUTERS.LOGIN} replace />
  );
};

export const routerConfig = [
  {
    path: ROUTERS.LOGIN,
    element: <LoginData />,
  },
  {
    path: ROUTERS.DASHBOARD,
    element: <ProtectedRoutes />,
    children: [
      {
        path: "/",
        element: <Navigate to={ROUTERS.DISCOVERY} replace={true} />,
      },
      {
        path: ROUTERS.DISCOVERY,
        element: <Discovery />,
      },
      {
        path: ROUTERS.GET_ADMIN_LOGIN_DATA,
        element: <MemberInformation />,
      },
      {
        path: ROUTERS.TIME_LINE,
        element: null,
      },
      {
        path: ROUTERS.ALERT,
        element: null,
      },
      {
        path: ROUTERS.MANAGEMENT_SETTING,
        element: <ManagementSettings />,
      },
    ],
  },
];

const router = createBrowserRouter(routerConfig);

export default router;
