import { useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { setUserData } from "../../../redux/slice/userSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/storeConfig";
import { getAdminDataThunk } from "../../../redux/thunk/userThunk";

type ModalName =
  | "CHANGE_AVATAR"
  | "CHANGE_PROFILE"
  | "CHANGE_EMAIL"
  | "CHANGE_PASSWORD";

export const useAccountSettings = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { aduserid, adcompanyid } = useAppSelector((state) => state.userInfo);
  const [modalName, setModalName] = useState<ModalName | null>(null);

  const openModal = (name: ModalName) => {
    setModalName(name);
  };

  const closeModal = () => {
    setModalName(null);
  };

  const logout = () => {
    dispatch(
      setUserData({
        adcompanyid: null,
        aduserid: null,
      })
    );
    navigate("/AdminLoginTop");
  };

  useLayoutEffect(() => {
    if (adcompanyid && aduserid)
      dispatch(
        getAdminDataThunk({
          payload: {
            adcompanyid,
            aduserid,
          },
        })
      );
  }, [dispatch, adcompanyid, aduserid]);

  return {
    modalName,
    openModal,
    closeModal,
    logout,
  };
};
