import {
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/table-core";
import { useCallback, useLayoutEffect, useState } from "react";
import { TfiMoreAlt } from "react-icons/tfi";
import {
  GetTeamUserPayload as GetTeamUserData,
  TeamFrame,
} from "../../../@types/Team";
import { TextTableItem } from "../../../components/parts/ManagementSettings/Typography/TextTableItem";
import { setTeam } from "../../../redux/slice/teamSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/storeConfig";
import {
  deleteTeamThunk,
  getTeamListThunk,
  getTeamUserDataThunk,
} from "../../../redux/thunk/teamThunk";
import { EMPTY_STRING } from "../../Const";
import { useMessage } from "../useMessage";

type ModalName = "TEAM_INFO" | "ADD_MEMBER" | "UPDATE_TEAM" | "DELETE_TEAM";

export const useTableTeam = () => {
  const columnHelper = createColumnHelper<TeamFrame>();
  const dispatch = useAppDispatch();
  const { showMessage } = useMessage();
  const { adcompanyid, aduserid } = useAppSelector((state) => state.userInfo);
  const { team } = useAppSelector((state) => state.team);
  const [modalName, setModalName] = useState<ModalName | null>(null);

  const openModal = useCallback(
    (name: ModalName, team: TeamFrame) => {
      setModalName(name);
      dispatch(setTeam(team));
    },
    [dispatch]
  );

  const closeModal = useCallback(() => {
    setModalName(null);
    dispatch(setTeam(null));
  }, [dispatch]);

  const getTeamUserData = useCallback(
    (teamid: string, team: TeamFrame) => {
      if (adcompanyid) {
        const payload: GetTeamUserData = {
          adcompanyid,
          adteamid: teamid,
        };
        dispatch(
          getTeamUserDataThunk({
            payload,
            onSuccess: () => {
              openModal("TEAM_INFO", team);
            },
          })
        );
      }
    },
    [dispatch, adcompanyid, openModal]
  );

  const addMemberIntoTeamModal = useCallback(
    (teamid: string, team: TeamFrame) => {
      if (adcompanyid) {
        const payload: GetTeamUserData = {
          adcompanyid,
          adteamid: teamid,
        };
        dispatch(
          getTeamUserDataThunk({
            payload,
            onSuccess: () => {
              openModal("ADD_MEMBER", team);
            },
          })
        );
      }
    },
    [dispatch, adcompanyid, openModal]
  );

  const deleteTeam = useCallback(() => {
    if (team && adcompanyid && aduserid) {
      dispatch(
        deleteTeamThunk({
          payload: {
            upcrud: "D",
            upcompanyid: adcompanyid,
            upteamid: team.gtteamid,
            upteamkana: team.gtteamkana,
            upteamname: team.gtteamname,
          },
          onSuccess: (message) => {
            showMessage({
              status: "success",
              title: message || EMPTY_STRING,
            });
            closeModal();
            dispatch(
              getTeamListThunk({
                payload: {
                  adcompanyid,
                  aduserid,
                },
              })
            );
          },
          onError: (message) => {
            showMessage({
              status: "error",
              title: message,
            });
          },
        })
      );
    }
  }, [adcompanyid, aduserid, team, dispatch, showMessage, closeModal]);

  const columns = [
    columnHelper.accessor("gtteamname", {
      cell: (info) => (
        <Text color="#2C2C2C" fontSize="12px" fontWeight="bold">
          {info.getValue()}
        </Text>
      ),
      header: "名前",
      sortingFn: "sortByJapanese",
    }),
    columnHelper.accessor("gtteamcount", {
      cell: (info) => <TextTableItem>{`${info.getValue()}人`}</TextTableItem>,
      header: "所属人数",
      enableSorting: false,
    }),
    columnHelper.accessor("gtteamid", {
      cell: (info) => (
        <Menu variant="custom">
          <Flex align="center" justifyContent="right" w="100%">
            <MenuButton
              as={IconButton}
              variant="outline"
              size="sm"
              textAlign="center"
              aria-label="Search team"
              borderColor="transparent"
              w="28px"
              h="28px"
              icon={<TfiMoreAlt size={16} />}
            />
          </Flex>
          <MenuList w="260px">
            <MenuItem
              onClick={() =>
                getTeamUserData(info.row.original.gtteamid, info.row.original)
              }
            >
              チーム情報を確認
            </MenuItem>
            <MenuItem
              onClick={() =>
                addMemberIntoTeamModal(
                  info.row.original.gtteamid,
                  info.row.original
                )
              }
            >
              メンバーを追加
            </MenuItem>
            <MenuItem
              onClick={() => openModal("UPDATE_TEAM", info.row.original)}
            >
              チーム名を変更
            </MenuItem>
            <MenuItem
              color="#FF4B45"
              onClick={() => openModal("DELETE_TEAM", info.row.original)}
            >
              削除
            </MenuItem>
          </MenuList>
        </Menu>
      ),
      header: "",
      enableSorting: false,
    }),
  ];

  useLayoutEffect(() => {
    if (adcompanyid && aduserid)
      dispatch(
        getTeamListThunk({
          payload: {
            adcompanyid,
            aduserid,
          },
        })
      );
  }, [adcompanyid, aduserid, dispatch]);

  return {
    columns,
    modalName,
    openModal,
    closeModal,
    deleteTeam,
  };
};
