import {
  Avatar,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/table-core";
import { useCallback, useState } from "react";
import { TfiMoreAlt } from "react-icons/tfi";
import { TeamUserFrame } from "../../../@types/Team";
import { TextTableItem } from "../../../components/parts/ManagementSettings/Typography/TextTableItem";
import { setMemberData } from "../../../redux/slice/memberSlice";
import { setTeamList, setTeamUserData } from "../../../redux/slice/teamSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/storeConfig";
import { addMemberIntoTeamThunk } from "../../../redux/thunk/teamThunk";
import { EMPTY_STRING } from "../../Const";
import { getName } from "../../util/helper";
import { useMessage } from "../useMessage";

type ModalName =
  | "CHANGE_PROFILE"
  | "ADD_MEMBER"
  | "UPDATE_TEAM"
  | "DELETE_MEMBER";

export const useTableTeamInfo = ({ onOk }: { onOk?: () => void }) => {
  const columnHelper = createColumnHelper<TeamUserFrame>();
  const { showMessage } = useMessage();
  const dispatch = useAppDispatch();
  const { teamUserData, teamList } = useAppSelector((state) => state.team);
  const { memberList } = useAppSelector((state) => state.member);
  const [modalName, setModalName] = useState<ModalName | null>(null);
  const [selectedMember, setSelectedMember] = useState<TeamUserFrame | null>(
    null
  );

  const openModal = (name: ModalName, member: TeamUserFrame) => {
    setModalName(name);
    setSelectedMember(member);
  };

  const onCloseModal = () => {
    setModalName(null);
    setSelectedMember(null);
  };

  const getMember = useCallback(
    (member: TeamUserFrame) => {
      const selectedMember = memberList.find(
        (item) => item.gtuserid === member.gtteamuserid
      );
      Promise.resolve(dispatch(setMemberData(selectedMember))).then(() => {
        openModal("CHANGE_PROFILE", member);
      });
    },
    [dispatch, memberList]
  );

  const deleteMember = useCallback(() => {
    if (teamUserData && selectedMember) {
      const remainedTeamUserData = teamUserData?.gtteamuserframe.filter(
        (item) => item.gtteamuserid !== selectedMember.gtteamuserid
      );
      dispatch(
        addMemberIntoTeamThunk({
          payload: {
            upcompanyid: teamUserData?.gtcompanyid,
            upteamid: teamUserData?.gtteamid,
            upteamuserid: remainedTeamUserData.map((item) => item.gtteamuserid),
          },
          onSuccess: (message) => {
            onCloseModal();
            onOk?.();
            showMessage({
              status: "success",
              title: message || EMPTY_STRING,
            });

            const newTeamMemberList = teamUserData?.gtteamuserframe.filter(
              (item) => item.gtteamuserid !== selectedMember.gtteamuserid
            );
            dispatch(
              setTeamUserData({
                ...teamUserData,
                gtteamuserframe: newTeamMemberList,
              })
            );

            const newTeamList = teamList.map((item) =>
              item.gtteamid === teamUserData?.gtteamid
                ? {
                    ...item,
                    gtteamcount: newTeamMemberList.length,
                  }
                : item
            );
            dispatch(setTeamList(newTeamList));
          },
          onError: (message) => {
            onCloseModal();
            showMessage({
              status: "error",
              title: message,
            });
          },
        })
      );
    }
  }, [selectedMember, teamList, teamUserData, dispatch, showMessage]);

  const columns = [
    columnHelper.accessor("gtuserfirstname", {
      cell: (info) => {
        return (
          <Flex align="center" gap="10px">
            <Avatar src={info.row.original?.gtuserpic} w="26px" h="26px" />
            <Text color="#2C2C2C" fontSize="12px" fontWeight="bold">
              {getName([
                info.row.original.gtuserlastname,
                info.row.original.gtuserfirstname,
              ])}
            </Text>
          </Flex>
        );
      },
      header: "",
    }),
    columnHelper.accessor("gtoccupation", {
      cell: (info) => <TextTableItem>{info.getValue()}</TextTableItem>,
      header: "",
    }),
    columnHelper.accessor("gttitle", {
      cell: (info) => <TextTableItem>{info.getValue()}</TextTableItem>,
      header: "",
    }),
    columnHelper.accessor("gtcontract", {
      cell: (info) => <TextTableItem>{info.getValue()}</TextTableItem>,
      header: "",
    }),
    columnHelper.accessor("gtauthority", {
      cell: (info) => <TextTableItem>{info.getValue()}</TextTableItem>,
      header: "",
    }),
    columnHelper.accessor("gtteamuserid", {
      cell: (info) => (
        <Menu variant="custom">
          <Flex align="center" justifyContent="right" w="100%">
            <MenuButton
              as={IconButton}
              variant="outline"
              size="sm"
              textAlign="center"
              aria-label="Search team"
              borderColor="transparent"
              w="28px"
              h="28px"
              icon={<TfiMoreAlt size={16} />}
            />
          </Flex>
          <MenuList w="260px">
            <MenuItem onClick={() => getMember(info.row.original)}>
              メンバー情報を編集する
            </MenuItem>
            <MenuItem
              color="#FF4B45"
              onClick={() => {
                openModal("DELETE_MEMBER", info.row.original);
              }}
            >
              チームから外す
            </MenuItem>
          </MenuList>
        </Menu>
      ),
      header: "",
    }),
  ];

  return {
    columns,
    modalName,
    openModal,
    onCloseModal,
    deleteMember,
  };
};
