import {
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { GetTeamUserPayload } from "../../../@types/Team";
import { CountRecallContext } from "../../../components/pages/Discovery";
import { setTeam, setTeamUserData } from "../../../redux/slice/teamSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/storeConfig";
import { getMemberListThunk } from "../../../redux/thunk/memberThunk";
import {
  deleteTeamThunk,
  getTeamListThunk,
  getTeamUserDataThunk,
} from "../../../redux/thunk/teamThunk";
import { EMPTY_STRING } from "../../Const";
import { useMessage } from "../useMessage";

export const useTeamMenu = () => {
  const dispatch = useAppDispatch();
  const { showMessage } = useMessage();
  const [modalName, setModalName] = useState<string | null>(null);
  const { adcompanyid, aduserid } = useAppSelector((state) => state.userInfo);
  const { team } = useAppSelector((state) => state.team);
  const [countRecall, setCountRecall] = useContext(CountRecallContext);
  const listModalName = useMemo(
    () => [
      { name: "チーム情報を確認", id: "TEAM_INFO" },
      { name: "メンバーを追加", id: "ADD_MEMBER" },
      { name: "チーム名を変更", id: "UPDATE_TEAM" },
      { name: "削除", id: "DELETE_TEAM" },
    ],
    []
  );

  const closeModal = useCallback(() => {
    setModalName(null);
    dispatch(setTeam(null));
    dispatch(setTeamUserData(null));
  }, [dispatch]);

  const onOpenModal = useCallback(
    ({ team, name }: { team: TeamInfo; name: string }) => {
      const modalNameTemp = listModalName.find(
        (modal) => name === modal.name
      )?.id;
      if (modalNameTemp) {
        switch (modalNameTemp) {
          case "TEAM_INFO":
          case "ADD_MEMBER":
            if (adcompanyid) {
              const payload: GetTeamUserPayload = {
                adcompanyid,
                adteamid: team?.teamid,
              };
              dispatch(
                getTeamUserDataThunk({
                  payload,
                  onSuccess: (empty, data) => {
                    dispatch(
                      setTeam({
                        gtteamid: data?.gtteamid,
                        gtteamname: data?.gtteamname,
                        gtteamkana: "",
                        gtteamcount: 0,
                      })
                    );
                    setModalName(modalNameTemp);
                  },
                })
              );
            }
            break;
          case "UPDATE_TEAM":
          case "DELETE_TEAM":
          default:
            console.log({ team });
            dispatch(
              setTeam({
                gtteamid: team?.teamid,
                gtteamname: team.teamname,
                gtteamkana: team.teamkana,
                gtteamcount: team.teamcount,
              })
            );

            setModalName(modalNameTemp);
            break;
        }
      }
    },
    [adcompanyid, dispatch, listModalName]
  );

  useLayoutEffect(() => {
    if (adcompanyid && aduserid) {
      dispatch(
        getTeamListThunk({
          payload: {
            adcompanyid,
            aduserid,
          },
        })
      );
      dispatch(
        getMemberListThunk({
          payload: {
            urcompanyid: adcompanyid,
          },
        })
      );
    }
  }, [adcompanyid, aduserid, dispatch]);

  const recallData = () => {
    setCountRecall?.(countRecall + 1);
  };

  const deleteTeam = useCallback(() => {
    if (team && adcompanyid && aduserid) {
      dispatch(
        deleteTeamThunk({
          payload: {
            upcrud: "D",
            upcompanyid: adcompanyid,
            upteamid: team.gtteamid,
            upteamkana: team.gtteamkana,
            upteamname: team.gtteamname,
          },
          onSuccess: (message) => {
            showMessage({
              status: "success",
              title: message || EMPTY_STRING,
            });
            closeModal();
            setCountRecall?.(countRecall + 1);
          },
          onError: (message) => {
            showMessage({
              status: "error",
              title: message,
            });
          },
        })
      );
    }
  }, [
    team,
    adcompanyid,
    aduserid,
    dispatch,
    showMessage,
    closeModal,
    setCountRecall,
    countRecall,
  ]);

  return {
    modalName,
    team,
    onOpenModal,
    closeModal,
    recallData,
    deleteTeam,
  };
};
