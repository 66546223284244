
const KEY = 'redux';

/**
 * @remarks
 * localStorageから値を取得する
 *
 * @returns
 * localStorageに保存された値のjson
 */
const loadFromLocalStorage = (): any => {
  try {
    const serializedState = localStorage.getItem(KEY);
    if (!serializedState) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    console.warn(e);
    return undefined;
  }
};

/**
 * @remarks
 * localStorageに値を保存する
 *
 * @param state localStorageに登録する値
 */
const saveToLocalStorage = (state: any): any => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(KEY, serializedState);
  } catch (e) {
    console.warn(e);
  }
};

export { loadFromLocalStorage, saveToLocalStorage };
