import { createSlice } from "@reduxjs/toolkit";
import { AdminData, AuthorityFrame } from "../../@types/User";
import {
  getAdminAuthorityListThunk,
  getAdminDataThunk,
  loginAdminTopThunk,
} from "../thunk/userThunk";

type UserState = {
  aduserid: string | null;
  adcompanyid: string | null;
  admin: AdminData | null;
  adminAuthorityList: AuthorityFrame[];
};

const initialState: UserState = {
  aduserid: null,
  adcompanyid: null,
  admin: null,
  adminAuthorityList: [],
};

const userSlice = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.aduserid = action.payload.aduserid;
      state.adcompanyid = action.payload.adcompanyid;
    },
  },
  extraReducers(builder) {
    builder.addCase(getAdminDataThunk.fulfilled, (state, action) => {
      state.admin = action.payload;
    });

    builder.addCase(getAdminAuthorityListThunk.fulfilled, (state, action) => {
      state.adminAuthorityList = action.payload;
    });

    builder.addCase(loginAdminTopThunk.fulfilled, (state, action) => {
      state.adcompanyid = action.payload?.[0]?.urcompanyid || null;
      state.aduserid = action.payload?.[0]?.urmailaddress || null;
    });
  },
});

export const { setUserData } = userSlice.actions;
export default userSlice.reducer;
