import { Badge, ChakraProvider } from "@chakra-ui/react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { theme } from "./styles/Theme";

import { DateStore } from "./lib/globalStates/DateState";
import { LoginUserProvider } from "./providers/LoginUserProvider";
import storeConfig from "./redux/storeConfig";
import router from "./router/routes";

import packageJson from "../package.json";
import Loading from "./components/parts/Loading";
import { axiosSetup } from "./configs/axiosConfig";
import "./styles/app.css";

async function enableMocking() {
  // if (process.env.NODE_ENV !== "development") {
  //   return;
  // }
  // const { worker } = await import("./mocks/browser");
  // // `worker.start()` returns a Promise that resolves
  // // once the Service Worker is up and ready to intercept requests.
  // return worker.start();
}

axiosSetup(storeConfig);
const App = () => {
  return (
    <Provider store={storeConfig}>
      <DateStore>
        <ChakraProvider theme={theme}>
          <Badge position="fixed" bottom={0} right={0}>
            {packageJson.version}
          </Badge>
          <LoginUserProvider>
            <RouterProvider router={router} />
          </LoginUserProvider>
          <Loading />
        </ChakraProvider>
      </DateStore>
    </Provider>
  );
};

enableMocking().then(() => {
  ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <App />
  );
});
