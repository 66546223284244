export const USER_ENDPOINT = {
  GET_ADMIN_PROFILE: `getAdminPersonalData`,
  CHANGE_ADMIN_PROFILE: `updAdminPersonalData`,
  CHANGE_PASSWORD: `updAdminUserPassword`,
  CHANGE_AVATAR: `updAdminUserPic`,
  // TODO
  CHANGE_EMAIL: ``,
  GET_ADMIN_AUTHORITY: `getAdminAuthority`,
  LOGIN_ADMIN: `AdminLoginTop`,
};

export const MEMBER_ENDPOINT = {
  GET_MEMBER_LIST: `getAdminUserData`,
  INVITE_MEMBER: `updAdminUserInvite`,
  GET_MEMBER: `getPersonalData`,
  UPDATE_MEMBER: `updAdminUserData`,
  DELETE_MEMBER: `updAdminUserData`,
  GET_TEAM_BELONG_MEMBER: `getAdminTeamBelongData`,
  UPDATE_TEAM_BELONG_MEMBER: `updAdminTeamBelongData`,
};

export const TEAM_ENDPOINT = {
  GET_TEAM_LIST: `getAdminTeamData`,
  CREATE_TEAM: `updAdminTeamData`,
  GET_TEAM_INFO: `getAdminTeamData`,
  ADD_MEMBER_INTO_TEAM: `updAdminTeamUserData`,
  UPDATE_TEAM: `updAdminTeamData`,
  GET_TEAM_USER_DATA: `getAdminTeamUserData`,
  DELETE_MEMBER_IN_TEAM: `updAdminTeamData`,
  DELETE_TEAM: `updAdminTeamData`,
};

export const ALERT_ENDPOINT = {
  GET_ADMIN_ALERT_APP_URL_DATA: `getAdminAlertappurlData`,
  GET_ADMIN_ALERT_MASTER_DATA: `getAdminAlertMaster`,
  GET_ADMIN_ALERT_DATA: `getAdminAlertData`,
  UPDATE_ADMIN_ALERT_DATA: `updAdminAlertData`,
};

export const IP_ENDPOINT = {
  GET_IP: `getAdminOfficeIP`,
  UPDATE_IP: `updAdminOfficeIP`,
};
