import { InfoOutlineIcon } from "@chakra-ui/icons";
import {
  Box,
  Divider,
  Flex,
  GridItem,
  HStack,
  Link,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import React, { useContext, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { TUserState } from "../../@types/User";
import { categoryColors } from "../../lib/Const";
import { DateContext } from "../../lib/globalStates/DateState";
import { formatMinute } from "../../lib/timeCalculate";
import CircleIcon from "./CircleIcon";
import { getUserURL } from "../../lib/util/helper";

type Props = {
  appInfoCountObject: [
    CalculatedCategoryPer1Minute | undefined,
    OrganizeAppInfoByCategory | {}
  ];
  i: number;
  showMinute: boolean;
  member: TeamMember;
  modalOpen: (time: number) => void;
};

// 特定の時間帯でもっともよく使ったアプリケーション情報をポップアップで表示するためのパーツ
const MostedUsedAppPopOverCustomSecond = React.memo((props: Props) => {
  const { appInfoCountObject, i, showMinute, member, modalOpen } = props;
  const { aduserid, adcompanyid } = useSelector(
    (state: TUserState) => state.userInfo
  );
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const [clickedTextIndex, setClickedTextIndex] = useState<number | 0>(0);
  const APP_NOT_USE_FILE_TITLE = "ファイルがありません";
  const MAX_APP_SHOWN = 3;
  // // グローバルで状態管理する日付情報を扱うためのhooks
  const [dateState] = useContext(DateContext);
  const numYear = dateState.getFullYear();
  const numMonth = dateState.getMonth() + 1;
  const numDate = dateState.getDate();
  const dateSelected = `${numYear}/${numMonth
    .toString()
    .padStart(2, "0")}/${numDate.toString().padStart(2, "0")}`;

  /**
   * 関数 `handleItemClick` は、特定のアイテムのクリック イベントを処理し、アイテムをスムーズにスクロールして表示するために使用されます。
   * @param event - イベント パラメーターは、クリックをトリガーしたマウス イベントです。これは React.MouseEvent<HTMLDivElement> 型です。
   * @param {number} indexItem - `indexItem` パラメータは、クリックされた項目のインデックスを表す数値です。
   */
  const handleItemClick = (
    event: React.MouseEvent<HTMLDivElement>,
    indexItem: number
  ) => {
    event.stopPropagation();
    const container = scrollContainerRef.current as HTMLDivElement | null;
    setClickedTextIndex(indexItem);
    if (container) {
      const itemToScrollTo = container.children[indexItem];
      if (itemToScrollTo) {
        itemToScrollTo.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
      }
    }
  };

  /**
   * scrollComponentClick 関数は、スクロール コンポーネントのクリック イベントを処理し、一連の値を循環します。
   */
  const scrollComponentClick = () => {
    let cyclicValues = categoryArray.length;
    const currentIndex = clickedTextIndex;
    if (categoryArray.length > 3) {
      cyclicValues = 3;
    }
    const nextIndex = (currentIndex + 1) % cyclicValues;
    handleItemClick(
      { stopPropagation: () => {} } as React.MouseEvent<HTMLDivElement>,
      nextIndex
    );
  };

  let categoryObject = appInfoCountObject[0];
  let appDetailObject = appInfoCountObject[1];
  let mostUsedCategory = "";

  function getTop3DetailsByCategory(
    data: OrganizeAppInfoByCategory,
    category: string
  ) {
    const categoryData = data[category];

    if (!categoryData) {
      return [];
    }

    const combinedDetails: AppInfoDetailSumData[] = categoryData.flatMap(
      (item) => item.detail
    );
    combinedDetails.sort((a, b) => b.useTime - a.useTime);

    const top3Details = combinedDetails.slice(0, 3).map((item) => ({
      useDetail: item.useDetail,
      infoPath: item.infoPath,
      useTime: item.useTime,
      infoType: item.infoType,
      category: item.category,
      appName:
        categoryData.find((categoryItem) => categoryItem?.detail.includes(item))
          ?.appName || "",
    }));
    return top3Details;
  }

  /**
   * この関数は、オブジェクトの配列を使用時間プロパティに基づいて並べ替えます。
   * @param {ArrayByUseTime} arr - パラメータ `arr` は、`AppInfoByCategoryData` オブジェクトの配列である `ArrayByUseTime`
   * 型です。
   */
  const originalObject = appDetailObject as OrganizeAppInfoByCategory;
  function sortArrayByUseTime(arr: ArrayByUseTime) {
    arr.sort(
      (a: AppInfoByCategoryData, b: AppInfoByCategoryData) =>
        b.useTime - a.useTime
    );
  }
  for (const key in originalObject) {
    if (Array.isArray(originalObject[key])) {
      sortArrayByUseTime(originalObject[key]);
    }
  }

  const sortedAppObject = originalObject;
  let appDetailArray = Object.keys(sortedAppObject);
  if (appDetailArray.length < 3) {
    appDetailArray[2] = "";
  }

  const categoryArray = Object.keys(appInfoCountObject[0] as object);
  if (categoryArray.length > 0) {
    mostUsedCategory = categoryArray[0];
  }

  /**
   * 関数 `handleCopyToClipboard` は、クリック イベントによってトリガーされたときに、指定されたコンテンツをクリップボードにコピーする TypeScript React
   * 関数です。
   * @param event - イベント パラメーターは、関数をトリガーしたマウス イベントです。この場合、これは HTMLDivElement タイプの React マウス
   * イベントです。つまり、div 要素で発生したイベントです。
   * @param {string} content - 「content」パラメータは、クリップボードにコピーするテキストを表す文字列です。
   */
  const handleCopyToClipboard = (
    event: React.MouseEvent<HTMLDivElement>,
    content: string
  ) => {
    event.stopPropagation();
    try {
      const textArea = document.createElement("textarea");
      textArea.value = `${content}`;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
    } catch (error) {
      console.error("Failed to copy text to clipboard:", error);
    }
  };
  return (
    <Popover placement="bottom-start" trigger="hover" key={i} isLazy>
      <PopoverTrigger>
        <GridItem
          key={`grid-${i}`}
          colStart={i + 1}
          colEnd={i + 2}
          h={{ "2xl": 10, xl: 8 }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          onClick={() => modalOpen(i)}
          cursor="pointer"
          onMouseEnter={() => setClickedTextIndex(0)}
        >
          <CircleIcon
            color="none"
            iconName={mostUsedCategory}
            width={1.875}
            height={1.875}
          />
        </GridItem>
      </PopoverTrigger>
      <PopoverContent
        cursor="pointer"
        w="18.75rem"
        height="15.8rem"
        border="none"
        boxShadow="rgba(0, 0, 0, 0.16) 0px 0.25rem 0.5rem, rgba(0, 0, 0, 0.23) 0px 0.25rem 0.5rem;"
        borderWidth={10}
        rounded="md"
        color="#2c2c2c"
        fontFamily="Source Han Sans"
        fontWeight="bold"
        onClick={scrollComponentClick}
      >
        <Flex flexDir="row" align="start" w="100%">
          <Flex
            mt="0.625rem"
            flexDir="column"
            alignItems="center"
            width="14%"
            fontSize="1.5rem"
            lineHeight="2.3rem"
            fontFamily="Roboto"
          >
            {categoryArray?.map((item, index: number) =>
              index > 2 ? null : (
                <Text
                  key={index}
                  cursor="pointer"
                  onClick={(event) => handleItemClick(event, index)}
                  style={{ opacity: clickedTextIndex === index ? 1 : 0.3 }}
                >
                  {index + 1}
                </Text>
              )
            )}
          </Flex>
          <Flex
            pr={2}
            flexDir="column"
            alignItems="center"
            align="start"
            width="86%"
            maxH="15.8rem"
            overflowY="auto"
            sx={{
              "&::-webkit-scrollbar": {
                width: "0",
              },
              "&::-webkit-scrollbar-thumb": {
                display: "none",
              },
              msOverflowStyle: "none",
              scrollbarWidth: "none",
            }}
            ref={scrollContainerRef}
          >
            {categoryArray?.map((itemCategory, index: number) =>
              index > 2 ? null : (
                <Box
                  key={index}
                  width="100%"
                  fontSize="0.8125rem"
                  fontWeight="bold"
                >
                  <Box width="100%" mt="0.625rem">
                    <HStack h="100%" justifyContent="space-between">
                      <HStack width="100%">
                        <CircleIcon
                          color="none"
                          iconName={itemCategory}
                          width={1.25}
                          height={1.25}
                          widthMediumScreen={0.9375}
                          heightMediumScreen={0.9375}
                        />
                        <Box m="0" width="100%">
                          <Flex flexDir="column" m="0">
                            <HStack alignItems="end" justifyContent="end">
                              <Text>
                                {showMinute && categoryObject
                                  ? formatMinute(categoryObject[itemCategory])
                                  : ""}
                              </Text>
                              <Flex
                                onClick={(event) => event.stopPropagation()}
                              >
                                <Link
                                  href={getUserURL({
                                    adcompanyid,
                                    adid: aduserid,
                                    dateSelected,
                                    memberid: member?.memberid,
                                    membername: member?.membername,
                                  })}
                                  isExternal
                                >
                                  <InfoOutlineIcon
                                    boxSize="4"
                                    color="#919191"
                                    cursor="pointer"
                                  />
                                </Link>
                              </Flex>
                            </HStack>
                          </Flex>
                        </Box>
                      </HStack>
                    </HStack>
                  </Box>
                  <Box mt="1rem">
                    {[...Array(MAX_APP_SHOWN)].map((_, indexItem) => {
                      const itemApp = getTop3DetailsByCategory(
                        appDetailObject,
                        itemCategory
                      )[indexItem];

                      return (
                        <React.Fragment key={indexItem}>
                          {itemApp ? (
                            <Box key={indexItem}>
                              <Box
                                display="flex"
                                justifyContent="space-between"
                              >
                                <Text
                                  color={categoryColors[itemCategory]?.color}
                                  fontWeight="bold"
                                  opacity="0.7"
                                  mb="2"
                                >
                                  {itemApp.appName}
                                </Text>
                                {itemApp.useDetail ? null : (
                                  <Text>
                                    {showMinute && categoryObject
                                      ? formatMinute(itemApp.useTime)
                                      : ""}
                                  </Text>
                                )}
                              </Box>
                              {!itemApp.useDetail ? (
                                <>
                                  <HStack
                                    justifyContent="space-between"
                                    mb="2"
                                    alignItems={"start"}
                                    height="1.625rem"
                                  >
                                    <Flex p="auto">
                                      {APP_NOT_USE_FILE_TITLE}
                                    </Flex>
                                  </HStack>
                                </>
                              ) : (
                                <HStack
                                  justifyContent="space-between"
                                  mb="2"
                                  alignItems={"start"}
                                >
                                  <Flex p="auto">
                                    <Popover
                                      placement="top"
                                      trigger="hover"
                                      isLazy
                                    >
                                      <PopoverTrigger>
                                        <Flex
                                          alignItems="center"
                                          cursor="pointer"
                                          onClick={(event) => {
                                            handleCopyToClipboard(
                                              event,
                                              itemApp?.infoType === "url" &&
                                                (/chrome/i.test(
                                                  itemApp?.appName
                                                ) ||
                                                  /edge/i.test(
                                                    itemApp?.appName
                                                  ))
                                                ? itemApp?.infoPath
                                                : itemApp?.useDetail
                                            );
                                          }}
                                        >
                                          <CircleIcon
                                            color="none"
                                            iconName={
                                              itemApp?.infoType === "url" &&
                                              (/chrome/i.test(
                                                itemApp?.appName
                                              ) ||
                                                /edge/i.test(itemApp?.appName))
                                                ? "copyLink"
                                                : "copyFile"
                                            }
                                            width={1.625}
                                            height={1.625}
                                            widthMediumScreen={1.1875}
                                            heightMediumScreen={1.1875}
                                          />
                                        </Flex>
                                      </PopoverTrigger>
                                      <PopoverContent
                                        bg="#ffffff"
                                        width="10.25rem"
                                        height="1.875rem"
                                        justifyContent="center"
                                        alignItems="center"
                                        fontFamily="Source Han Sans"
                                        boxShadow="1px 1px 3px #00000029"
                                        w="auto"
                                      >
                                        <Text mx="0.4rem">
                                          {itemApp?.infoType === "url" &&
                                          (/chrome/i.test(itemApp?.appName) ||
                                            /edge/i.test(itemApp?.appName))
                                            ? "URLをコピー"
                                            : "ファイル名をコピー"}
                                        </Text>
                                      </PopoverContent>
                                    </Popover>
                                    <Popover
                                      placement="top-start"
                                      trigger="hover"
                                      isLazy
                                    >
                                      <PopoverTrigger>
                                        <Flex
                                          alignItems="center"
                                          cursor="pointer"
                                        >
                                          <Text
                                            ml="0.35rem"
                                            my="auto"
                                            maxW="11rem"
                                            whiteSpace="nowrap"
                                            overflow="hidden"
                                            textOverflow="ellipsis"
                                          >
                                            {itemApp?.useDetail}
                                          </Text>
                                        </Flex>
                                      </PopoverTrigger>
                                      <PopoverContent
                                        bg="#ffffff"
                                        justifyContent="center"
                                        alignItems="start"
                                        fontFamily="Source Han Sans"
                                        boxShadow="1px 1px 3px #00000029"
                                        boxSizing="content-box"
                                        w="auto"
                                        p="0.3rem"
                                      >
                                        <Text
                                          mx="0.2rem"
                                          my="auto"
                                          onClick={(event) =>
                                            event.stopPropagation()
                                          }
                                          maxW="15rem"
                                        >
                                          {itemApp?.useDetail}
                                        </Text>
                                        <Text
                                          mx="0.2rem"
                                          my="auto"
                                          onClick={(event) =>
                                            event.stopPropagation()
                                          }
                                          maxW="15rem"
                                        >
                                          {/chrome/i.test(itemApp?.appName) ||
                                          /edge/i.test(itemApp?.appName) ? (
                                            <Link
                                              href={itemApp?.infoPath}
                                              isExternal
                                              m="0.2rem"
                                            >
                                              {itemApp?.infoPath}
                                            </Link>
                                          ) : (
                                            <></>
                                          )}
                                        </Text>
                                      </PopoverContent>
                                    </Popover>
                                  </Flex>
                                  <Text my="auto" alignItems={"end"}>
                                    {showMinute && categoryObject
                                      ? formatMinute(itemApp?.useTime)
                                      : ""}
                                  </Text>
                                </HStack>
                              )}

                              {indexItem >= 2 ||
                              indexItem >=
                                getTop3DetailsByCategory(
                                  appDetailObject,
                                  itemCategory
                                ).length -
                                  1 ? (
                                <Divider
                                  my="2"
                                  borderTop="1px solid #d6d6d6"
                                  display="none"
                                />
                              ) : (
                                <Divider my="2" borderTop="1px solid #d6d6d6" />
                              )}
                            </Box>
                          ) : (
                            <>
                              <Box
                                key={indexItem}
                                my="2"
                                height="3.96875rem"
                              ></Box>
                            </>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </Box>
                </Box>
              )
            )}
          </Flex>
        </Flex>
      </PopoverContent>
    </Popover>
  );
});
MostedUsedAppPopOverCustomSecond.displayName =
  "MostedUsedAppPopOverCustomSecond";
export default MostedUsedAppPopOverCustomSecond;
