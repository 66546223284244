import { AxiosResponse } from "axios";
import {
  DeleteMember,
  GetMemberList,
  MemberDataPayload,
  MemberFrame,
  MemberListData,
  TAdminTeamBelongData,
  TGetAdminTeamBelongDataPayload,
  TUpdateAdminTeamBelongDataPayload,
  UpdateMemberProfile,
} from "../@types/Member";
import { BaseResponse } from "../@types/Response";
import axiosInstance from "../configs/axiosConfig";
import { MEMBER_ENDPOINT } from "../configs/endpoints";
import { USER_API_URL } from "../configs/env";

export const memberServices = {
  getMemberList: async (
    payload: GetMemberList
  ): Promise<AxiosResponse<MemberListData[]>> => {
    const data = new FormData();
    data.append("adcompanyid", payload.urcompanyid);
    const response: AxiosResponse<MemberListData[]> = await axiosInstance.post(
      MEMBER_ENDPOINT.GET_MEMBER_LIST,
      data
    );
    return response;
  },
  getMember: async (
    payload: MemberDataPayload
  ): Promise<AxiosResponse<MemberFrame[]>> => {
    var data = new FormData();
    data.append("urcompanyid", payload.urcompanyid);
    data.append("uruserid", payload.uruserid);
    const response: AxiosResponse<MemberFrame[]> = await axiosInstance.post(
      `${USER_API_URL}${MEMBER_ENDPOINT.GET_MEMBER}`,
      data
    );
    return response;
  },
  updateMember: async (
    payload: UpdateMemberProfile
  ): Promise<AxiosResponse<BaseResponse[]>> => {
    const response: AxiosResponse<BaseResponse[]> = await axiosInstance.post(
      MEMBER_ENDPOINT.UPDATE_MEMBER,
      payload
    );
    return response;
  },
  deleteMember: async (
    payload: DeleteMember
  ): Promise<AxiosResponse<BaseResponse[]>> => {
    const response: AxiosResponse<BaseResponse[]> = await axiosInstance.post(
      MEMBER_ENDPOINT.DELETE_MEMBER,
      payload
    );
    return response;
  },
  getAdminTeamBelongData: async (
    payload: TGetAdminTeamBelongDataPayload
  ): Promise<AxiosResponse<TAdminTeamBelongData[]>> => {
    const data = new FormData();
    data.append("adcompanyid", payload.adcompanyid);
    data.append("aduserid", payload.aduserid);
    const response = await axiosInstance.post(
      MEMBER_ENDPOINT.GET_TEAM_BELONG_MEMBER,
      data
    );
    return response;
  },
  updateAdminTeamBelongData: async (
    payload: TUpdateAdminTeamBelongDataPayload
  ): Promise<AxiosResponse<BaseResponse[]>> => {
    const response = await axiosInstance.post(
      MEMBER_ENDPOINT.UPDATE_TEAM_BELONG_MEMBER,
      payload
    );
    return response;
  },
};
