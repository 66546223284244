import { AxiosResponse } from "axios";
import {
  TAdminAlertMaster,
  TGetAdminAlertDataPayload,
  TAdminAlert,
  TUpdateAdminAlertDataPayload,
  TAdminAlertAppUrlData,
} from "../@types/Alert";
import { BaseResponse } from "../@types/Response";
import axiosInstance from "../configs/axiosConfig";
import { ALERT_ENDPOINT } from "../configs/endpoints";

export const alertServices = {
  getAdminAlertAppUrlData: async (payload: TGetAdminAlertDataPayload) => {
    const data = new FormData();
    data.append("adcompanyid", payload.adcompanyid);
    const response: AxiosResponse<TAdminAlertAppUrlData[]> =
      await axiosInstance.post(
        ALERT_ENDPOINT.GET_ADMIN_ALERT_APP_URL_DATA,
        data
      );
    return response;
  },
  getAdminAlertMasterData: async () => {
    const response: AxiosResponse<TAdminAlertMaster[]> =
      await axiosInstance.post(ALERT_ENDPOINT.GET_ADMIN_ALERT_MASTER_DATA);
    return response;
  },
  getAdminAlertData: async (payload: TGetAdminAlertDataPayload) => {
    const data = new FormData();
    data.append("adcompanyid", payload.adcompanyid);
    const response: AxiosResponse<TAdminAlert[]> = await axiosInstance.post(
      ALERT_ENDPOINT.GET_ADMIN_ALERT_DATA,
      data
    );
    return response;
  },
  updateAdminAlertData: async (payload: TUpdateAdminAlertDataPayload) => {
    const response: AxiosResponse<BaseResponse[]> = await axiosInstance.post(
      ALERT_ENDPOINT.UPDATE_ADMIN_ALERT_DATA,
      payload
    );
    return response;
  },
  deleteAdminAlertData: async (payload: TUpdateAdminAlertDataPayload) => {
    const response: AxiosResponse<BaseResponse[]> = await axiosInstance.post(
      ALERT_ENDPOINT.UPDATE_ADMIN_ALERT_DATA,
      payload
    );
    return response;
  },
};
