import { AxiosResponse } from "axios";
import { BaseResponse } from "../@types/Response";
import {
  AddMemberIntoTeam,
  CreateTeam,
  DeleteTeam,
  GetTeamList,
  GetTeamUserPayload,
  TeamData,
  TeamUserData,
  UpdateTeam,
} from "../@types/Team";
import axiosInstance from "../configs/axiosConfig";
import { TEAM_ENDPOINT } from "../configs/endpoints";

export const teamServices = {
  getTeamList: async (
    payload: GetTeamList
  ): Promise<AxiosResponse<TeamData[]>> => {
    const data = new FormData();
    data.append("adcompanyid", payload.adcompanyid);
    data.append("aduserid", payload.aduserid);
    const response: AxiosResponse<TeamData[]> = await axiosInstance.post(
      TEAM_ENDPOINT.GET_TEAM_LIST,
      data
    );
    return response;
  },
  createTeam: async (
    payload: CreateTeam
  ): Promise<AxiosResponse<BaseResponse[]>> => {
    const data = new FormData();
    data.append("upcrud", payload.upcrud);
    data.append("upcompanyid", payload.upcompanyid);
    data.append("upteamname", payload.upteamname);
    data.append("upteamkana", payload.upteamkana);
    const response = await axiosInstance.post(
      TEAM_ENDPOINT.CREATE_TEAM,
      payload
    );
    return response;
  },
  getTeamUserData: async (
    payload: GetTeamUserPayload
  ): Promise<AxiosResponse<TeamUserData[]>> => {
    const data = new FormData();
    data.append("adcompanyid", payload.adcompanyid);
    data.append("adteamid", payload.adteamid);
    const response = await axiosInstance.post(
      TEAM_ENDPOINT.GET_TEAM_USER_DATA,
      data
    );
    return response;
  },
  addMemberIntoTeam: async (
    payload: AddMemberIntoTeam
  ): Promise<AxiosResponse<BaseResponse[]>> => {
    const response = await axiosInstance.post(
      TEAM_ENDPOINT.ADD_MEMBER_INTO_TEAM,
      payload
    );
    return response;
  },
  updateTeam: async (
    payload: UpdateTeam
  ): Promise<AxiosResponse<BaseResponse[]>> => {
    const response = await axiosInstance.post(
      TEAM_ENDPOINT.UPDATE_TEAM,
      payload
    );
    return response;
  },
  deleteTeam: async (
    payload: DeleteTeam
  ): Promise<AxiosResponse<BaseResponse[]>> => {
    const response = await axiosInstance.post(
      TEAM_ENDPOINT.DELETE_TEAM,
      payload
    );
    return response;
  },
};
