import { Box, Flex, Heading } from "@chakra-ui/react";
import { useLayoutEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/storeConfig";
import { getAdminAuthorityListThunk } from "../../redux/thunk/userThunk";
import { AccountSettings } from "../parts/ManagementSettings/AccountSettings";
import { AlertManagement } from "../parts/ManagementSettings/AlertManagement";
import { MemberManagement } from "../parts/ManagementSettings/MemberManagement";
import { MenuSettingList } from "../parts/ManagementSettings/MenuSettingList";
import { OtherSettings } from "../parts/ManagementSettings/OtherSettings";
import { TeamManagement } from "../parts/ManagementSettings/TeamManagement";
import {
  getAdminAlertAppUrlDataThunk,
  getAdminAlertMasterDataThunk,
} from "../../redux/thunk/alertThunk";

enum MenuItem {
  AccountSettings,
  MemberManagement,
  TeamManagement,
  AlertManagement,
  OtherSettings,
}

const menuItems: MenuItemType[] = [
  { id: MenuItem.AccountSettings, text: "管理者アカウント設定" },
  { id: MenuItem.MemberManagement, text: "メンバー管理" },
  { id: MenuItem.TeamManagement, text: "チーム管理" },
  { id: MenuItem.AlertManagement, text: "アラート管理" },
  { id: MenuItem.OtherSettings, text: "その他" },
];

const ManagementSettings = () => {
  const dispatch = useAppDispatch();
  const { adcompanyid } = useAppSelector((state) => state.userInfo);
  const [menuItem, setMenuItem] = useState<MenuItemType>(menuItems[0]);

  const onSelect = (item: MenuItemType) => {
    setMenuItem(item);
  };

  const renderScreen = (id: number) => {
    switch (id) {
      case MenuItem.AccountSettings:
        return <AccountSettings />;
      case MenuItem.MemberManagement:
        return <MemberManagement />;
      case MenuItem.TeamManagement:
        return <TeamManagement />;
      case MenuItem.AlertManagement:
        return <AlertManagement />;
      case MenuItem.OtherSettings:
        return <OtherSettings />;
      default:
        return null;
    }
  };

  useLayoutEffect(() => {
    if (adcompanyid) {
      dispatch(
        getAdminAuthorityListThunk({
          payload: {
            adcompanyid,
          },
        })
      );
      dispatch(getAdminAlertMasterDataThunk());
      dispatch(
        getAdminAlertAppUrlDataThunk({
          payload: {
            adcompanyid,
          },
        })
      );
    }
  }, [adcompanyid, dispatch]);

  return (
    <Box maxW="1300px" mx="auto">
      <Flex gap="20px" w="100%">
        <MenuSettingList
          menuItems={menuItems}
          onSelect={onSelect}
          selectedId={menuItem.id}
        />

        <Box flex={1}>
          <Heading
            mb="18px"
            fontSize="21px"
            fontWeight={800}
            lineHeight="30px"
            color="#2C2C2C"
          >
            {menuItem.text}
          </Heading>
          {renderScreen(menuItem.id)}
        </Box>
      </Flex>
    </Box>
  );
};

ManagementSettings.displayName = "ManagementSettings";
export default ManagementSettings;
