import { Flex, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import {
  ColumnDef,
  SortingFn,
  SortingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import * as React from "react";
import { GoArrowDown, GoArrowUp } from "react-icons/go";
import { TextTableHeader } from "./ManagementSettings/Typography/TextTableHeader";
import { sortByJapanese } from "../../lib/util/helper";

declare module "@tanstack/table-core" {
  interface SortingFns {
    sortByJapanese: SortingFn<unknown>;
  }
}

export type CommonTableProps<Data extends object> = {
  data: Data[];
  columns: ColumnDef<Data, any>[];
  showHeader?: boolean;
  containerClass?: string;
};

export function CommonTable<Data extends object>({
  data,
  columns,
  showHeader = true,
  containerClass,
}: CommonTableProps<Data>) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
    sortingFns: {
      sortByJapanese: (rowA, rowB, columnId) => {
        const numA = String(rowA.getValue(columnId));
        const numB = String(rowB.getValue(columnId));
        return sortByJapanese(numA, numB);
      },
    },
  });

  return (
    <Table className={containerClass}>
      <Thead>
        {showHeader ? (
          table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const meta: any = header.column.columnDef.meta;
                return (
                  <Th
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    isNumeric={meta?.isNumeric}
                    _first={{
                      paddingLeft: 0,
                    }}
                    _last={{
                      paddingRight: 0,
                    }}
                    py="10px"
                    borderColor="#D6D6D6"
                    cursor="pointer"
                  >
                    <Flex align="center" gap="4px" height="16px">
                      <TextTableHeader>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </TextTableHeader>
                      {header.column.getIsSorted() ? (
                        header.column.getIsSorted() === "desc" ? (
                          <GoArrowDown
                            size="16px"
                            aria-label="sorted descending"
                          />
                        ) : (
                          <GoArrowUp
                            size="16px"
                            aria-label="sorted ascending"
                          />
                        )
                      ) : null}
                    </Flex>
                  </Th>
                );
              })}
            </Tr>
          ))
        ) : (
          <Tr borderTop="1px solid #D6D6D6" />
        )}
      </Thead>

      <Tbody>
        {table.getRowModel().rows.map((row) => (
          <Tr key={row.id}>
            {row.getVisibleCells().map((cell) => {
              const meta: any = cell.column.columnDef.meta;
              return (
                <Td
                  key={cell.id}
                  isNumeric={meta?.isNumeric}
                  _first={{
                    paddingLeft: 0,
                  }}
                  _last={{
                    paddingRight: 0,
                  }}
                  py="16px"
                  borderColor="#D6D6D6"
                >
                  <Flex h="21px" align="center" w="100%">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Flex>
                </Td>
              );
            })}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}
