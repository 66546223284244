import { UpdateAdminOfficeIPPayload } from "./../@types/Ip";
import { AxiosResponse } from "axios";
import {
  GetAdminOfficeIPPayload,
  GetAdminOfficeIPResponse,
} from "../@types/Ip";
import axiosInstance from "../configs/axiosConfig";
import { IP_ENDPOINT } from "../configs/endpoints";
import { BaseResponse } from "../@types/Response";

export const ipServices = {
  getAdminOfficeIP: async (payload: GetAdminOfficeIPPayload) => {
    const data = new FormData();
    data.append("adcompanyid", payload.adcompanyid);
    const response: AxiosResponse<GetAdminOfficeIPResponse[]> =
      await axiosInstance.post(IP_ENDPOINT.GET_IP, data);
    return response;
  },
  updateAdminOfficeIP: async (payload: UpdateAdminOfficeIPPayload) => {
    const response: AxiosResponse<BaseResponse[]> = await axiosInstance.post(
      IP_ENDPOINT.UPDATE_IP,
      payload
    );
    return response;
  },
};
